<template>
  <div :class="`pk_${$store.state.mode}`">
    <template v-if="$store.state.mode == 'pc'">
      <headerTop title="竞技场" readme="玩法说明" @handleReadme="handleilltate" />
      <!-- 房间状态 -->
      <div class="btn_wrap">
        <div class="home_status">
          <div :class="['status_btn', { active: status == item.status }]" v-for="item in statusBtns" :key="item.status" @click="handleStatus(item.status)">
            <i :class="item.icon"></i>
            <span>{{ item.btn }}</span>
          </div>
          <div class="model_btn">
            <span class="text">玩法模式： </span>
            <div @click="handleModel(null)" :class="['btn', { active_m: model == null }]">全部</div>
            <div @click="handleModel(0)" :class="['btn', { active_m: model == 0 }]">欧皇</div>
            <div @click="handleModel(1)" :class="['btn', { active_m: model == 1 }]">非酋</div>
          </div>
        </div>
        <div class="create_pk btn_click" @click="handleCreat">创建新对战+</div>
      </div>
      <el-table rowKey="id" :data="resultList" class="pk_table" style="width: 100%" header-row-class-name="table_header" row-class-name="table_row" cell-class-name="table_cell" v-loading="tableLoading" :default-sort="{ prop: 'boxPriceTotal', order: 'descending' }">
        <el-table-column prop="roundNumber" label="回合" min-width="80px">
          <template slot-scope="scope">
            <div :class="['round_number', scope.row.model == 0 ? 'ouhuang' : 'feiyou']">
              <p>
                <b>{{ scope.row.roundNumber }}</b>
                <span>回合</span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="boxData" label="盒子" min-width="300px">
          <template slot-scope="scope">
            <div class="box_list">
              <div class="box_item" v-for="(item, index) in scope.row.boxData" :boxItem="item" :key="index" @click="showBoxDetail(item.boxId)">
                <div class="box_img"><img :src="item.boxImg01" alt="" /></div>
                <!-- <div class="box_name">{{ item.name || '箱子名称' }}</div> -->
                <div class="box_num">{{ item.number }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="boxData" label="模式" min-width="80px">
          <template slot-scope="scope">
            <span class="model" :style="{ color: scope.row.model == 0 ? 'gold' : 'red' }">{{ scope.row.model == 0 ? '欧皇' : '非酋' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="seats" label="玩家" min-width="100px">
          <template slot-scope="scope">
            <div class="avatar_box" :class="{ active: aitem.isHouseOwner == 0 }" v-for="(aitem, aindex) in scope.row.seats" :key="aindex + 'a'">
              <img v-if="aitem.playerId" :src="aitem.avatar" alt class="avatar_box_hive" />
              <span v-else class="avatar_box_hive"></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="boxPriceTotal" label="价值" sortable min-width="80px">
          <template slot-scope="scope">
            <price :price="scope.row.boxPriceTotal" size="large" />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center" min-width="100px">
          <template slot-scope="scope">
            <div @click="handleBtn(scope.row)" :class="scope.row.status == 0 ? 'join_btn' : 'look_btn'">
              <template v-if="scope.row.status == 0">加入房间</template>
              <template v-if="scope.row.status == 1">观看战斗</template>
              <template v-if="scope.row.status == 2 || scope.row.status == 3">查看结果</template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background v-if="status == 1 && haveHistory" layout="prev, next" :current-page="earlierHistoryPage.page" :page-size="earlierHistoryPage.size" @current-change="getEarlierHistory" />
      <el-pagination background v-if="status == 2" layout="prev, pager, next" :hide-on-single-page="true" :total="roomTotal" :current-page="roomPage.page" :page-size="roomPage.size" @current-change="queryFightList" />
      <el-pagination background v-if="status == 3" layout="prev, pager, next" :hide-on-single-page="true" :total="myListTotal" :current-page="myListPage.page" :page-size="myListPage.size" @current-change="getMyFightList" />
    </template>
    <template v-else>
      <headerTop className="header" title="竞技场" readme="玩法说明" @handleReadme="handleilltate">
        <div class="creat_pk center btn_click" @click="handleCreat">创建房间</div>
      </headerTop>
      <!-- 房间状态 -->
      <div class="home_status space_around">
        <div :class="['status_btn center', { active: status == item.status }]" v-for="item in statusBtns" :key="item.status" @click="handleStatus(item.status)">{{ item.btn }}</div>
      </div>
      <!-- 房间列表 -->
      <div class="room_list" @scroll="roomScroll">
        <template v-if="resultList.length">
          <div class="room" v-for="(item, index) in resultList" :key="index">
            <div class="room_top space_between">
              <div>
                <div v-if="item.status == '0'" class="room_top_status space_around">
                  <svg t="1708854733700" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4598">
                    <path
                      d="M512 0l60.416 325.973333 240.5376-228.181333-142.711467 299.246933 328.704-43.246933L707.584 512l291.362133 158.208-328.704-43.246933 142.677334 299.246933-240.469334-228.181333L512 1024l-60.416-325.973333-240.5376 228.181333 142.711467-299.246933-328.704 43.246933L316.416 512 25.053867 353.792l328.704 43.246933-142.677334-299.246933 240.469334 228.181333L512 0z"
                      :fill="$main"
                      p-id="4599"
                    />
                  </svg>
                  等待中
                </div>
                <div v-if="item.status == '1'" class="room_top_status space_around">进行中</div>
                <div v-if="item.status == '2'" class="room_top_status space_around">
                  <svg t="1708854386621" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200">
                    <path
                      d="M512 0l60.416 325.973333 240.5376-228.181333-142.711467 299.246933 328.704-43.246933L707.584 512l291.362133 158.208-328.704-43.246933 142.677334 299.246933-240.469334-228.181333L512 1024l-60.416-325.973333-240.5376 228.181333 142.711467-299.246933-328.704 43.246933L316.416 512 25.053867 353.792l328.704 43.246933-142.677334-299.246933 240.469334 228.181333L512 0z"
                      fill="#727477"
                      p-id="4450"
                    />
                  </svg>
                  已结束
                </div>
              </div>
              <div class="room_model center">
                <div v-if="item.model == 0" class="ouhuang">欧皇</div>
                <div v-if="item.model == 1" class="feiqiu">非酋</div>
              </div>
              <div>回合数：{{ item.roundNumber }}</div>
            </div>
            <div class="room_avatar left">
              <div class="center" :class="aitem.isHouseOwner == 0 ? 'active' : '0'" v-for="(aitem, aindex) in item.seats" :key="aindex + 'a'">
                <img v-if="aitem.playerId" :src="aitem.avatar" alt class="avatar_box_hive" />
              </div>
              <div v-for="(pitem, pindex) in item.seats" :key="pindex + 'p'">
                <div class="avatar_box_null center" v-if="pitem.playerId == null">
                  <i class="el-icon-circle-plus-outline"></i>
                </div>
              </div>
            </div>
            <!-- 宝箱 -->
            <div class="room_box space_between">
              <div class="room_box_left space_between">
                <div class="room_box_left_arms_box">
                  <div class="room_box_left_arms" :style="{ left: '-' + sss + 'px' }">
                    <div class="arms center" v-for="(bitem, bindex) in item.boxData" :key="bindex + 'b'" @click="showBoxDetail(bitem.boxId)">
                      <img :src="bitem.boxImg01" alt class="arms_img" />
                      <div class="center_number center">{{ bitem.number }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <price :price="item.boxPriceTotal" />
            </div>
            <div class="room_btn btn_click" @click="handleBtn(item)">
              <div class="room_btn_jiaru center" v-if="item.status == '0'">进入</div>
              <div class="room_btn_guanzhan center" v-if="item.status == '1'">观战</div>
              <div class="room_btn_jieshu center" v-if="item.status == '2' || item.status == '3'">查看</div>
            </div>
          </div>
          <div class="over_text">{{ isOver }}</div>
        </template>
        <div class="not_data" v-else>--暂无数据--</div>
      </div>
    </template>
    <boxDetail v-if="showDetail.showDetail" :showDetail="showDetail" :boxId="boxDetailId"></boxDetail>
  </div>
</template>

<script>
import { getFightList, getUserInfo, fightOnMyOwn, earlierHistory } from '@/api/index';
import Socket from '@/utils/socket';

export default {
  data() {
    return {
      showDetail:{showDetail:false},
      boxDetailId:null,
      model: null,
      status: 1,
      tableLoading: false,
      statusBtns: [
        { btn: '进行中', icon: 'el-icon-scissors', status: 1, params: [0, 1] },
        { btn: '已结束', icon: 'el-icon-switch-button', status: 2, params: [2] },
        { btn: '参与过', icon: 'el-icon-finished', status: 3, params: [3] },
      ],
      earlierHistoryPage: {
        fightId: 0,
        page: 1,
        size: 10,
      },
      haveHistory: false,
      sss: 1,
      // 房间列表
      roomList: [],
      roomTotal: 0,
      roomIsOver: '上拉加载更多',
      roomPage: {
        page: 1,
        size: 10,
      },
      // 我的对战列表
      myList: [],
      myListTotal: 0,
      myListIsOver: '上拉加载更多',
      myListPage: {
        page: 1,
        size: 10,
      },
    };
  },
  watch: {
    roomList(newValue) {
      if (newValue.length) {
        this.earlierHistoryPage.fightId = newValue[newValue.length - 1].id;
      }
    },
  },
  computed: {
    resultList() {
      return this.status == 3 ? this.myList : this.roomList;
    },
    isOver() {
      return this.status == 3 ? this.myListIsOver : this.roomIsOver;
    },
    statusList() {
      return this.statusBtns.filter(item => item.status == this.status)[0].params;
    },
  },
  mounted() {
    // 建立ws连接
    this.createWs();
    this.queryFightList(1);
  },
  beforeRouteLeave(to, from, next) {
    this.$socket?.close();
    next();
  },
  methods: {
    showBoxDetail(id){
      this.boxDetailId=id
      this.showDetail.showDetail=true
    },
    // 获取房间列表
    queryFightList(page) {
      if (page) this.roomPage.page = page;
      if (page == 1) this.roomList = [];
      const params = { ...this.roomPage, statusList: this.statusList };
      if (this.model !== null) params.model = this.model;
      getFightList(params).then(res => {
        const { list, total } = res?.data?.data;
        (list || []).map(item => {
          let obj = JSON.parse(item.boxData);
          let valueList = [];
          for (let i in obj) valueList.push(obj[i]);
          return (item.boxData = valueList);
        });
        if (this.$store.state.mode == 'pc') {
          this.roomList = [...list];
          this.roomTotal = total || 0;
        } else {
          this.roomList.push(...list);
          this.roomIsOver = list.length < this.roomPage.size ? '暂无更多' : '上拉加载更多';
        }

        // 查看有没有历史遗留的房间
        let timer = setTimeout(() => {
          if (this.status == 1 && this.$store.state.mode == 'pc') {
            earlierHistory(this.earlierHistoryPage).then(res => {
              const { data, code } = res?.data || {};
              if (code == 200 && data.length) {
                this.haveHistory = true;
              }
            });
          }
          clearTimeout(timer);
        }, 200);
      });
    },
    fightOnMyOwn(params){
      if (this.model !== null) params.model = this.model;
      fightOnMyOwn(params).then(res => {
        let { list, total } = res?.data?.data || {};
        (list || []).map(item => {
          let obj = JSON.parse(item.boxData);
          let valueList = [];
          for (let i in obj) valueList.push(obj[i]);
          return (item.boxData = valueList);
        });

        if (this.$store.state.mode == 'pc') {
          this.myList = [...list];
          this.myListTotal = total || 0;
        } else {
          this.myList.push(...list);
          this.myListIsOver = list.length < this.myListPage.size ? '暂无更多' : '上拉加载更多';
        }
      });
    },
    //获取我的对战
    getMyFightList(page) {
      this.myListIsOver = '加载中';
      if (page) this.myListPage.page = page;
      if (page == 1) this.myList = [];
      const userId = this.$store.state.USER_INFO.userId;
      let params 
      if (userId) {
        params = { ...this.myListPage, playerId: userId, statusList: [0, 1, 2, 3] };
        this.fightOnMyOwn(params)
      }else{
        //获取用户信息
      getUserInfo().then(res => {
        if (res?.data?.code != 200) return;
        params = { ...this.myListPage, playerId: res?.data?.data?.userId, statusList: [0, 1, 2, 3] };
        this.fightOnMyOwn(params);
      });
      }
      
      // console.log(this.userId);
      
     
    },
    // 获取更早的房间
    getEarlierHistory(page) {
      if (page) this.earlierHistoryPage.page = page - 1;
      earlierHistory(this.earlierHistoryPage).then(res => {
        console.log('获取更早的房间==>', res);
        this.roomIsOver = '加载中';
        const { data, code } = res?.data || {};
        if (code == 200) {
          if (this.$store.state.mode == 'pc') {
            if (data.length) {
              this.roomList = [...data];
            } else {
              this.$message({ message: '暂无更多', type: 'warning' });
            }
          } else {
            this.roomList.push(...data);
            this.roomIsOver = data.length < this.earlierHistoryPage.size ? '暂无更多' : '上拉加载更多';
          }
        }
      });
    },
    roomScroll(e) {
      const { scrollTop, clientHeight, scrollHeight } = e.srcElement;
      if (scrollTop + clientHeight + 5 >= scrollHeight) {
        if (this.status == 3 && this.myListIsOver == '上拉加载更多') {
          this.myListPage.page++;
          let timer = setTimeout(() => {
            this.getMyFightList();
            clearTimeout(timer);
          }, 5);
        } else {
          if (this.roomIsOver == '上拉加载更多') {
            if (this.status == 1 && this.roomList.length >= 10) {
              this.earlierHistoryPage.page = this.earlierHistoryPage.page - 1;
              this.earlierHistoryPage.page++;
              let timer = setTimeout(() => {
                this.getEarlierHistory();
                clearTimeout(timer);
              }, 5);
              return;
            }
            if (this.status == 2) {
              this.roomPage.page++;
              let timer = setTimeout(() => {
                this.queryFightList();
                clearTimeout(timer);
              }, 5);
            }
          }
        }
      }
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleBtn({ id }) {
      this.playAudio();
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      this.$router.push({ name: 'roomDetail', query: { id } });
    },
    createWs() {
      const userId = this.$store.state.USER_INFO.userId;
      if (userId) {
        this.connectWs(userId);
        return;
      }
      //获取用户信息
      getUserInfo().then(res => {
        if (res?.data?.code != 200) return;
        this.connectWs(res?.data?.data?.userId);
      });
    },
    //websocket连接
    connectWs(userId) {
      this.$socket = new Socket({
        url: '/ws/fight/hall/' + userId,
        watchLogin: true,
        onmessage: msg => {
          if (!msg.key) return;
          let i = (this.roomList || []).findIndex(item => item.id == msg.data[0].id);
          if (i == -1) {
            if (msg.data[0].status == 2 || msg.data[0].status == 3) {
              return;
            } else {
              if (this.roomList.length < 10) {
                this.roomList.unshift(...msg.data);
              } else {
                this.roomList.unshift(...msg.data);
                this.roomList.splice(this.roomList.length - msg.data.length, msg.data.length);
              }
            }
          } else {
            if (msg.data[0].status == 2 || msg.data[0].status == 3) {
              this.roomList.splice(i, 1);
            } else {
              this.roomList.splice(i, 1, msg.data[0]);
            }
          }
        },
      });
    },
    //玩法说明
    handleilltate() {
      this.$bus.$emit('is_illustrate', true);
      setTimeout(() => {
        this.$bus.$emit('nr_illustrate', { cont: 'pk' });
      }, 100);
      this.playAudio();
    },
    handleModel(model) {
      this.playAudio();
      this.model = model;
      this.queryList();
    },
    //参与进行结束
    handleStatus(status) {
      this.playAudio();
      this.status = status;
      this.queryList();
    },
    queryList() {
      if (this.status == 3) {
        this.getMyFightList(1);
      } else {
        this.queryFightList(1);
      }
    },
    //创建房间
    handleCreat() {
      this.playAudio();
      this.$router.push({
        name: 'creatroom',
      });
    },
    lotteryTest() {
      // 跳转
      this.$router.push({
        name: 'lottery',
        query: {},
      });
    },
    lotterySeatTest() {
      // 跳转
      this.$router.push({
        name: 'lotterySeat',
        query: {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pk_pc {
  margin: 0 @pcSpace;
  .btn_wrap {
    .flex-a-center;
    justify-content: space-between;
    .home_status {
      display: flex;
      .status_btn {
        .sc(18px, #fff);
        .btn-style(160px, 40px);
        border-radius: 20px;
        margin-right: 20px;
        transition: left 0.5s ease-in-out;
        span {
          padding-left: 4px;
        }
        &.active {
          background: #1a2b2e;
          .btn-shadow;
        }
      }
      .model_btn {
        .flex-a-center;
        .text,
        .btn {
          .sc(18px, #fff);
        }
        .text {
          padding-right: 4px;
        }
        .btn {
          position: relative;
          margin-right: 14px;
        }
        .active_m {
          color: @main;
          &::after {
            content: '';
            .abs-cl;
            .triangle-up(6px, @main);
            bottom: -8px;
          }
        }
      }
    }
    .create_pk {
      .btn-shadow;
      .sc(18px, #fff);
      .btn-style(160px, 40px);
      margin-top: 14px;
      border-radius: 20px;
      background: #1a2b2e;
    }
  }
}

.pk_mobile {
  left: 0;
  right: 0;
  .flex-column;
  top: @headerH;
  bottom: @footerH;
  position: absolute;
  .header_top {
    margin: 0 @mSpace;
  }
  .center_number {
    font-size: 13px;
    width: 21px;
    height: 21px;
    position: absolute;
    right: -11px;
    top: 50%;
    transform: translateY(-50%);
    background: #048a42;
    border-radius: 50%;
  }
  .room_btn_guanzhan {
    width: 100%;
    height: 30px;
    font-size: 15px;
    margin: 0 auto;
    background-image: url('../../assets/image/pk_watch.png');
    background-size: 100% 100%;
  }
  .room_list {
    flex: 1;
    width: 100%;
    margin: 0 auto;
    overflow-y: auto;
    padding: 0 @mSpace;
    box-sizing: border-box;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: @main;
      border-radius: 5px;
    }
    .room {
      padding: 12px;
      margin-top: 10px;
      border: 1px solid @bd1;
      background: #0d1214;
      border-radius: 14px;
      font-size: 12px;
      &:first-of-type {
        margin-top: 0;
      }
      .room_top {
        margin: 0 auto;
        .room_top_status {
          width: 59px;
          font-size: 12px;
          .icon {
            width: 15px;
            height: 15px;
            animation: infinite-spin 5s linear infinite;
          }
        }
        .room_model {
          width: 100px;
          color: white;
        }
        .ouhuang {
          color: gold;
        }
        .feiqiu {
          color: red;
        }
      }
      .room_avatar {
        margin: 0 4px;
        margin-top: 10px;
        .avatar_box_hive {
          width: 30px;
          height: 30px;
          opacity: 1;
          border-radius: 50%;
          margin-right: 14px;
          overflow: hidden;
        }
        .active {
          border: 1px solid @main;
        }
        .avatar_box_null {
          width: 30px;
          height: 30px;
          margin-right: 14px;
          border-radius: 50%;
          border: 1px solid @main;
          background: rgba(@main, 0.2);
          i {
            color: @main;
          }
        }
      }
      .room_box {
        margin: 0 auto;
        margin-top: 10px;
        -webkit-overflow-scrolling: touch;
        .room_box_left {
          width: 78%;
          .btn {
            width: 19px;
            height: 19px;
            background: #323436;
            opacity: 1;
            border-radius: 50%;
            i {
              color: RGBA(26, 28, 31, 1);
              font-weight: bold;
            }
          }
          .room_box_left_arms_box {
            overflow: hidden;
            position: relative;
            height: 45px;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch; /* 在移动设备上启用触摸滚动 */
            &::-webkit-scrollbar {
              height: 5px;
            }
            &::-webkit-scrollbar-track {
              display: none;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(50, 52, 54, 1);
              border-radius: 5px;
            }
            .room_box_left_arms {
              display: flex;
              .arms {
                height: 35px;
                background: #323436;
                border-radius: 10px 10px 10px 10px;
                opacity: 1;
                margin: 2px 10px 2px 2px;
                padding: 0 6px;
                position: relative;
                .arms_img {
                  width: 26px;
                }
              }
            }
          }
        }
      }
      .room_btn {
        margin: 0 auto;
        margin-top: 2%;
        .room_btn_jiaru {
          margin: 0 auto;
          width: 100%;
          height: 30px;
          font-size: 15px;
          .btn-bg;
        }
        .room_btn_jieshu {
          width: 100%;
          height: 32px;
          background: linear-gradient(360deg, rgba(78, 78, 78, 0.1) 0%, rgba(78, 78, 78, 0.05) 100%);
          opacity: 1;
          border: 1px solid #4e4e4e;
        }
      }
    }
  }
  .home_status {
    width: 240px;
    height: 34px;
    font-size: 14px;
    margin: 2% auto;
    background: #323436;
    border-radius: 70px;
    color: rgba(114, 116, 119, 1);
    .status_btn {
      width: 80px;
      height: 34px;
      border-radius: 70px;
      transition: left 0.5s ease-in-out;
    }
    .active {
      background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
      color: #fff;
    }
  }
  .header_top {
    ::v-deep .readme {
      left: 0;
      right: auto;
    }
    .creat_pk {
      .abs-ct;
      right: 4px;
      font-size: 12px;
      .btn-style(80px, 26px);
      .btn-bg;
    }
  }
}

@keyframes infinite-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<style lang="less">
.pk_table {
  .pc-show;
  margin-top: 43px;
  background: transparent;
  &::before {
    display: none;
  }
  .descending .sort-caret.descending {
    border-top-color: @main;
  }
  .ascending .sort-caret.ascending {
    border-bottom-color: @main;
  }
  .caret-wrapper {
    transform: scale(0.8);
  }
  .el-table__body {
    margin-top: -10px;
    table-layout: auto;
    border-collapse: separate;
    border-spacing: 0 14px; /* 设置表格行之间的间距为20px */
  }
  .table_header {
    background: transparent;
    th {
      padding: 0 !important;
      border: none !important;
      background: transparent !important;
      .cell {
        .sc(18px, #DDDDDD);
      }
      &:nth-of-type(1) .cell,
      &:nth-of-type(2) .cell,
      &:nth-of-type(4) .cell {
        padding-left: 32px;
      }
    }
  }
  .table_row {
    background: transparent;
    td {
      border: none;
      background: #0d1214;
      &:first-child {
        border-radius: 15px 0 0 15px;
      }
      &:last-child {
        position: relative;
        border-radius: 0 15px 15px 0;
        .cell {
          .abs-ctl;
        }
      }
    }
  }
  .table_cell {
    padding: 10px 0;
    .cell {
      .flex-a-center;
      .round_number {
        .wh(80px, 80px);
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        p {
          .abs-ctl;
          .wh(76px, 76px);
          .sc(30px, #fff);
          z-index: 2;
          text-align: center;
          background: #0d1214;
          border-radius: 50%;
          line-height: 76px;
          b {
            font-family: ysb !important;
          }
          span {
            .sc(14px, #fff);
          }
        }
        &::before {
          content: '';
          .abs-ctl;
          z-index: 1;
          .wh(100%, 100%);
          border-radius: 50%;
        }
        &.feiyou::before {
          background: linear-gradient(90deg, gold, deeppink);
        }
        &.ouhuang::before {
          background: linear-gradient(90deg, #25f484, @main);
        }
      }
      .box_list {
        .flex-center;
        .box_item {
          width: auto;
          padding: 10px 4px 0;
          position: relative;
          align-items: center;
          &:first-of-type {
            padding-left: 0;
          }
          &:last-of-type {
            padding-right: 0;
          }
          & > div {
            line-height: normal;
          }
          .flex-column;
          .box_img {
            .wh(90px, 50px);
            .bg-shadow(36px);
            position: relative;
            img {
              .wh(100%, auto);
              .abs-ctl;
            }
          }
          .box_name {
            .ell;
            .sc(14px, #fff);
            max-width: 110px;
            margin: 12px 0 4px;
          }
          .box_num {
            top: 4px;
            right: 0;
            .wh(24px,24px);
            .sc(14px, #fff);
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            background: #048a42;
          }
        }
      }
      .model {
        .sc(18px, #fff);
      }
      .avatar_box {
        .wh(42px, 42px);
        margin-right: 6px;
        .avatar_box_hive {
          .wh(38px, 38px);
          border-radius: 50%;
          display: inline-block;
          border: 1px solid #fff;
        }
      }
      .look_btn {
        .sc(18px, #ddd);
        .btn-style(140px, 40px);
        border-radius: 24px;
        background: @bg1;
        font-weight: bold;
      }
      .join_btn {
        border: none;
        .wh(150px, 48px);
        .sc(18px, #fff);
        line-height: 48px;
        text-align: center;
        font-weight: bold;
        background: url(../../assets/image/loginbtn.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .el-table__body tr:hover > td.el-table__cell {
    background-color: #0d1214 !important;
  }
  .el-table_1_column_3,
  .el-table_1_column_5 {
    text-align: center;
    .cell {
      .flex-center;
      padding: 0 !important;
    }
  }
}
</style>
